<template>
  <div>
    <div no-gutters class="mb-3 d-flex">
      <span
        v-if="intraWeekPrecision"
        class="shrink pt-2 ml-7 mr-4 subtitle-2 grey--text"
        >All</span
      >
      <v-range-slider
        v-model="scheduleSetterValue"
        :max="max"
        :min="min"
        hide-details
        dense
        class="align-center grow"
      >
        <template #prepend>
          <span class="circle-content">{{ scheduleSetterValue[0] }}</span>
        </template>
        <template #append>
          <span class="circle-content">{{ scheduleSetterValue[1] }}</span>
        </template>
      </v-range-slider>
    </div>
    <div v-if="intraWeekPrecision">
      <ERow
        v-for="(day, key) in Object.keys(schedule)"
        :key="key"
        no-gutters
        class="my-n2"
      >
        <ECol class="d-flex py-0 mr-4 shrink">
          <v-checkbox
            v-model="enabledDays[day]"
            :disabled="
              scheduleType === ScheduleType.Continuous ||
              scheduleType === ScheduleType.WorkingHours
            "
            :label="day[0]"
            @change="changeSchedule"
          />
        </ECol>
        <ECol class="d-flex grow">
          <v-range-slider
            v-model="schedule[day]"
            :max="max"
            :min="min"
            hide-details
            dense
            class="align-center"
            :disabled="!enabledDays[day]"
            :readonly="
              scheduleType === ScheduleType.Continuous ||
              scheduleType === ScheduleType.WorkingHours
            "
            @click="changeSchedule"
          >
            <template #prepend>
              <span class="circle-content">
                {{ schedule[day][0] }}
              </span>
            </template>
            <template #append>
              <span class="circle-content">
                {{ schedule[day][1] }}
              </span>
            </template>
          </v-range-slider>
        </ECol>
      </ERow>
    </div>
  </div>
</template>

<script>
import schedules, {
  toNumericSchedule,
} from "@evercam/shared/constants/schedules"
import { ScheduleType } from "@evercam/shared/types"

export default {
  name: "Schedule",
  props: {
    scheduleType: {
      type: String,
      default: "",
      required: true,
    },
    preloadSchedule: {
      type: [String, Object],
      default: () => "",
      required: false,
    },
    intraWeekPrecision: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      min: 0,
      max: 23,
      enabledDays: {
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: false,
        Sunday: false,
      },
      schedule: toNumericSchedule(schedules.WORKING_HOURS),
      scheduleSetterValue: [0, 23],
      ScheduleType,
    }
  },
  watch: {
    scheduleSetterValue(val) {
      Object.keys(this.schedule).forEach((day) => {
        this.schedule[day] = val
      })
      this.changeSchedule()
    },
    preloadSchedule() {
      this.loadSchedule()
    },
    scheduleType: {
      handler() {
        if (
          [ScheduleType.Continuous, ScheduleType.OnSchedule]?.includes(
            this.scheduleType
          )
        ) {
          this.schedule = toNumericSchedule(schedules.CONTINUOUS)
          this.enableAllDaysExcept()
        } else if (this.scheduleType === ScheduleType.WorkingHours) {
          this.schedule = toNumericSchedule(schedules.WORKING_HOURS)
          this.enableAllDaysExcept(["Saturday", "Sunday"])
        }
      },
      immediate: true,
    },
    schedule() {
      this.changeSchedule()
    },
  },
  mounted() {
    this.loadSchedule()
  },
  methods: {
    loadSchedule() {
      if (!this.preloadSchedule) {
        return
      }

      let tempSchedule =
        typeof this.preloadSchedule === "string"
          ? JSON.parse(this.preloadSchedule)
          : this.preloadSchedule

      tempSchedule = Object.entries(tempSchedule).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [`${key[0].toUpperCase()}${key.slice(1)}`]: value,
        }),
        {}
      )

      this.schedule = Object.keys(schedules.EMPTY).reduce((acc, day) => {
        this.enabledDays[day] = tempSchedule[day].length !== 0
        if (tempSchedule[day].length) {
          return {
            ...acc,
            [day]: tempSchedule[day][0]
              ?.split("-")
              ?.map((h) => Number.parseInt(h?.split(":")[0])),
          }
        }

        return {
          ...acc,
          [day]: [0, 23],
        }
      }, {})
    },
    convertJSON() {
      let json = {}
      Object.keys(this.schedule).forEach((day) => {
        const interval = this.schedule[day]
        json[day] = !this.enabledDays[day]
          ? []
          : [interval.map((h) => (h < 10 ? `0${h}:00` : `${h}:00`)).join("-")]
      })

      return JSON.stringify(json)
    },
    changeSchedule() {
      if (this.intraWeekPrecision) {
        this.$emit("input", this.convertJSON())
      } else {
        this.$emit("input", this.scheduleSetterValue)
      }
    },
    enableAllDaysExcept(except = []) {
      Object.keys(this.enabledDays).forEach((day) => {
        this.enabledDays[day] = !except?.includes(day)
      })
    },
  },
}
</script>

<style scoped>
.circle-content {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px 0px 0px 0px;
  background: #fff;
  border: 3px solid #1976d2;
  text-align: center;
}
</style>
