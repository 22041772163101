<template>
  <v-dialog v-model="hideShowDialog" scrollable max-width="300px">
    <template #activator="{ on: onDialog, attrs: attrsDialog }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
          <v-btn
            color="primary"
            small
            v-bind="{ ...attrsDialog, ...attrsTooltip }"
            v-on="{ ...onDialog, ...onTooltip }"
          >
            <v-icon>fa-columns</v-icon>
          </v-btn>
        </template>
        <span> Add or Remove Columns </span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Show/Hide Columns</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-3" style="max-height: 300px">
        <template v-for="(item, i) in tableFields">
          <v-checkbox
            v-if="item.text !== ''"
            :key="`item-${i}`"
            v-model="selectedHeaders"
            :value="item"
            :label="item.text"
            class="mt-0"
            hide-details
          ></v-checkbox>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeHideShow">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

export default {
  props: {
    tableFields: {
      type: Array,
      required: true,
    },
    selectedHeadersList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      hideShowDialog: false,
    }
  },
  computed: {
    ...mapStores(useReportStore),
    selectedHeaders: {
      get() {
        return this.selectedHeadersList.length > 0
          ? this.selectedHeadersList
          : this.reportStore.selectedHeaders
      },
      set(value) {
        if (this.selectedHeadersList.length > 0) {
          this.$emit("update-selectedHeaders", value)
        } else {
          this.reportStore.selectedHeaders = value
        }
      },
    },
  },
  methods: {
    closeHideShow() {
      this.hideShowDialog = false
    },
  },
}
</script>
