<template>
  <div>
    <v-tooltip left>
      <template #activator="{ on, attrs }">
        <v-btn
          v-show="fab"
          v-scroll="onScroll"
          fab
          dark
          fixed
          bottom
          right
          color="primary"
          v-bind="attrs"
          class="bottomTop"
          @click="toTop"
          v-on="on"
        >
          <v-icon small>fa-chevron-up</v-icon></v-btn
        >
      </template>
      <span>Go to top</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "TheAppBar",
  data: () => {
    return {
      fab: false,
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
  },
}
</script>

<style scoped>
.bottomTop {
  bottom: 45px;
}
</style>
