<template>
  <div>
    <span v-if="value === true" class="green--text text--darken-2"> Yes </span>
    <span v-else class="red--text text--darken-2"> No </span>
  </div>
</template>

<script>
export default {
  name: "ShowActiveValue",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
