<template>
  <div v-if="item.sims && item.sims.length > 0">
    <v-tooltip v-for="(sim, index) in item.sims" :key="sim.id" bottom>
      <template #activator="{ on, attrs }">
        <span
          class="primary--text cursor-pointer"
          v-bind="attrs"
          @click="openSmsHistoryDialog(sim)"
          v-on="on"
        >
          {{
            index !== item.sims.length - 1
              ? `${sim[numberField]},`
              : sim[numberField]
          }}
        </span>
      </template>
      Open SMS History
    </v-tooltip>
  </div>
  <span v-else>-</span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    numberField: {
      type: String,
      default: () => "number",
    },
  },
  methods: {
    openSmsHistoryDialog(value) {
      this.$emit("open-sms-history-dialog", value)
    },
  },
}
</script>
