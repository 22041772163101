<template>
  <v-snackbar
    v-model="notificationStore.snackbar"
    class="text-center"
    :color="notificationStore.color"
    :timeout="timeout"
    :transition="false"
    top
  >
    <span class="max-width">
      {{ notificationStore.text }}
    </span>
    <template #action="{ attrs }">
      <v-btn
        color="grey lighten-3"
        icon
        x-small
        v-bind="attrs"
        @click="$notifications.push({ snackbar: false })"
      >
        <v-icon>fa-times</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapStores } from "pinia"
import { useNotificationStore } from "@evercam/shared/stores/notification"

export default {
  name: "TheSnackbar",
  data() {
    return {
      defaultTimeout: 3000,
    }
  },
  computed: {
    ...mapStores(useNotificationStore),
    timeout() {
      return this.notificationStore?.timeout || this.defaultTimeout
    },
  },
}
</script>

<style>
.max-width {
  max-width: 400px;
}
</style>
